// eslint-disable-next-line
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import BaseVue from "@/plugin/BaseVue";
import * as model from "./Model";
import axios from "axios";

@Component
export default class extends BaseVue {
  public model = new model.Model();
  public created() {
    //
  }
  public async mounted() {
    await this.authCheck();
  }
  public backStep1() {
    this.model.step = "1";
  }
  public validationCheck(target = "", inputing = false, auto = false): boolean {
    let result = true;
    let input: any;
    let errors: any;
    if (this.model.step === "1") {
      if (target == "all" || target == "mailAddress") {
        input = this.model.mailAddress;
        errors = new Array<model.Error>();
        //
        if (!inputing) {
          input.value = input.value.trim();
          input.value = this.hankaku2Zenkaku(input.value);
        }
        if (input.value.length === 0) {
          errors.push(new model.Error("必須項目です"));
        } else if (
          input.value.match(/^[\w\-._]+@[\w\-._]+\.[A-Za-z]+$/g) == null
        ) {
          errors.push(new model.Error("対応していないメールアドレス形式です"));
        }
        if (input.value.length > input.maxLength) {
          errors.push(
            new model.Error(`${input.maxLength}文字以下で入力してください`)
          );
        }
        //
        if (inputing) {
          if (input.errors.length > errors.length) {
            input.errors = errors;
          }
        } else {
          input.errors = errors;
        }
        if (input.isError) {
          result = false;
        }
        //
      }
    }
    if (this.model.step === "2") {
      if (target == "all" || target == "code") {
        input = this.model.code;
        errors = new Array<model.Error>();
        //
        if (input.value.length === 0) {
          errors.push(new model.Error("必須項目です"));
        } else if (input.value.match(/^\d{6}$/g) == null) {
          errors.push(new model.Error("数字6桁を入力してください"));
        }
        //
        if (inputing) {
          if (input.errors.length > errors.length) {
            input.errors = errors;
          }
        } else {
          input.errors = errors;
        }
        if (input.isError) {
          result = false;
        }
        //
      }
      if (target == "all" || target == "password") {
        input = this.model.password;
        errors = new Array<model.Error>();
        //
        if (input.view) {
          if (input.value1.length === 0) {
            errors.push(new model.Error("必須項目です"));
          } else if (input.value1.length < input.minLength) {
            errors.push(
              new model.Error(`${input.minLength}文字以上で入力してください`)
            );
          } else if (
            input.value1.match(
              /^[a-z|A-Z|0-9|^$*.[\]{}()?\-“!@#%&/,><’:;|_~`]{6,99}$/
            ) == null
          ) {
            errors.push(new model.Error("対応していない文字が含まれます"));
          }
          if (input.value1.length > input.maxLength) {
            errors.push(
              new model.Error(`${input.maxLength}文字以下で入力してください`)
            );
          }
        } else {
          if (input.value1.length === 0) {
            errors.push(new model.Error("必須項目です"));
          } else if (input.value1.length < input.minLength) {
            errors.push(
              new model.Error(`${input.minLength}文字以上で入力してください`)
            );
          } else if (
            input.value1.match(
              /^[a-z|A-Z|0-9|^$*.[\]{}()?\-“!@#%&/,><’:;|_~`]{6,99}$/
            ) == null
          ) {
            errors.push(new model.Error("対応していない文字が含まれます"));
          }
          if (input.value1.length > input.maxLength) {
            errors.push(
              new model.Error(`${input.maxLength}文字以下で入力してください`)
            );
          }
          if (input.value1 !== input.value2) {
            errors.push(new model.Error("確認用パスワードが一致しません"));
          }
        }
        //
        if (inputing) {
          if (input.errors.length > errors.length) {
            input.errors = errors;
          }
        } else {
          input.errors = errors;
        }
        if (input.isError) {
          result = false;
        }
        //
      }
    }
    return result;
  }
  public async step1Process(): Promise<void> {
    this.model.step1ButtonActive = false;
    this.model.step1ButtonLoading = true;
    this.model.step1Errors = new Array<model.Error>();
    const validationCheck = this.validationCheck("all");
    if (!validationCheck) {
      await this.sleep(1000);
      this.model.step1ButtonActive = true;
      this.model.step1ButtonLoading = false;
      return;
    }
    let response: any;
    this.loadingOn();
    await axios
      .post(`https://${process.env.VUE_APP_API_URL}/SignIn/ForgotPassword`, {
        mailAddress: this.model.mailAddress.value,
      })
      .then((response) => {
        response = response.data;
        this.model.step = "2";
      })
      .catch((ex) => {
        const errors = ex.response.data.errors;
        console.log(errors);
        for (let i = 0; i < errors.length; i++) {
          switch (errors[i].code) {
            default:
              this.model.step1Errors.push(
                new model.Error("不明なエラーが発生しました")
              );
              throw ex;
          }
        }
      });
    this.loadingOff();
    this.model.step1ButtonActive = true;
    this.model.step1ButtonLoading = false;
  }
  public async step2Process(): Promise<void> {
    this.model.step2ButtonActive = false;
    this.model.step2ButtonLoading = true;
    this.model.step2Errors = new Array<model.Error>();
    const validationCheck = this.validationCheck("all");
    if (!validationCheck) {
      await this.sleep(1000);
      this.model.step2ButtonActive = true;
      this.model.step2ButtonLoading = false;
      return;
    }
    let response: any;
    this.loadingOn();
    await axios
      .post(`https://${process.env.VUE_APP_API_URL}/SignIn/ForgotPasswordConfirm`, {
        mailAddress: this.model.mailAddress.value,
        code: this.model.code.value,
        password: this.model.password.value1,
      })
      .then((response) => {
        response = response.data;
        this.model.step = "3";
      })
      .catch((ex) => {
        const errors = ex.response.data.errors;
        console.log(errors);
        for (let i = 0; i < errors.length; i++) {
          switch (errors[i].code) {
            case "001":
              this.model.step2Errors.push(
                new model.Error("確認コードが違います")
              );
              break;
            default:
              this.model.step2Errors.push(
                new model.Error("不明なエラーが発生しました")
              );
              throw ex;
          }
        }
      });
    this.loadingOff();
    this.model.step2ButtonActive = true;
    this.model.step2ButtonLoading = false;
  }
  public async step3Process(): Promise<void> {
    this.$emit("forgotPasswordCompleted", {
      mailAddress: this.model.mailAddress.value,
      password: this.model.password.value1,
    });
  }
  public async close(): Promise<void> {
    this.$emit("close");
  }
  public codeOnKey(event: KeyboardEvent): void {
    if (event.type == "keydown") {
      if (event.key == "Backspace") {
        switch (this.model.code.focusNumber) {
          case 1:
            this.model.code.value1 = "";
            break;
          case 2:
            this.model.code.value2 = "";
            break;
          case 3:
            this.model.code.value3 = "";
            break;
          case 4:
            this.model.code.value4 = "";
            break;
          case 5:
            this.model.code.value5 = "";
            break;
          case 6:
            this.model.code.value6 = "";
            break;
        }
      }
      switch (event.key) {
        case "Backspace":
        case "ArrowLeft":
        case "ArrowUp":
          if (this.model.code.focusNumber == 1) {
            break;
          }
          (document as Document)
            .getElementById(
              "codeValue" + (this.model.code.focusNumber - 1).toString()
            )
            ?.focus();
          break;
        case "ArrowRight":
        case "ArrowDown":
        case "Enter":
          if (this.model.code.focusNumber == 6) {
            break;
          }
          (document as Document)
            .getElementById(
              "codeValue" + (this.model.code.focusNumber + 1).toString()
            )
            ?.focus();
          break;
      }
      this.validationCheck("code", true);
    } else {
      (
        (document as Document).getElementById(
          "codeValue" + this.model.code.focusNumber.toString()
        ) as HTMLInputElement
      ).select();
    }
  }
  public codeChange(event: InputEvent): void {
    const inputNuber = (this.model.code as any)[
      "value" + this.model.code.focusNumber
    ].replace(/[^0-9]/g, "");
    if (inputNuber.length === 6) {
      (this.model.code as any)["value" + this.model.code.focusNumber] =
        inputNuber;
    } else {
      (this.model.code as any)["value" + this.model.code.focusNumber] =
        inputNuber.charAt(0);
    }
    if (event.data == undefined) {
      const temp = (this.model.code as any)[
        "value" + this.model.code.focusNumber
      ].replace(/[^0-9]/g, "");
      for (let i = 0; i < temp.length; i++) {
        if (i + this.model.code.focusNumber > 6) {
          break;
        }
        (this.model.code as any)["value" + (i + this.model.code.focusNumber)] =
          temp.substr(0 + i, 1);
      }
      return;
    }
    if (event.data == event.data.replace(/[^0-9]/g, "")) {
      if (event.data.length == 1) {
        if (this.model.code.focusNumber !== 6) {
          (document as Document)
            .getElementById(
              "codeValue" + (this.model.code.focusNumber + 1).toString()
            )
            ?.focus();
        }
      }
    }
  }
  public codeFocus(focusNumber: number): void {
    this.model.code.focusNumber = focusNumber;
    (
      (document as Document).getElementById(
        "codeValue" + this.model.code.focusNumber.toString()
      ) as HTMLInputElement
    ).select();
  }
}
