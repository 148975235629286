var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-page" }, [
    _c("div", { staticClass: "modal-header" }, [
      _c(
        "div",
        { staticClass: "closeButton" },
        [
          _c("fa-icon", {
            attrs: { icon: "times" },
            on: {
              click: function ($event) {
                return _vm.close()
              },
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "modal-body" }, [
      _c("ul", { staticClass: "step" }, [
        _c("li", { class: { selected: _vm.model.step === "1" } }, [
          _c("span", [_vm._v("基本情報を入力")]),
        ]),
        _c(
          "li",
          { staticClass: "nextIcon" },
          [_c("fa-icon", { attrs: { icon: "angle-double-right" } })],
          1
        ),
        _c("li", { class: { selected: _vm.model.step === "2" } }, [
          _c("span", [_vm._v("メールアドレスの確認")]),
        ]),
        _c(
          "li",
          { staticClass: "nextIcon" },
          [_c("fa-icon", { attrs: { icon: "angle-double-right" } })],
          1
        ),
        _c("li", { class: { selected: _vm.model.step == "3" } }, [
          _c("span", [_vm._v("アカウント登録完了")]),
        ]),
      ]),
      _vm.model.step === "1"
        ? _c(
            "div",
            {
              staticClass: "step1",
              class: { loading: _vm.model.step1ButtonLoading },
            },
            [
              _c("div", { staticClass: "formGroup" }, [
                _c(
                  "div",
                  { staticClass: "left" },
                  [_c("fa-icon", { attrs: { icon: "envelope" } })],
                  1
                ),
                _c("div", { staticClass: "right" }, [
                  _c(
                    "label",
                    {
                      staticClass: "title",
                      attrs: { for: "model.mailAddress.value" },
                    },
                    [_vm._v("メールアドレス")]
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.model.mailAddress.value,
                        expression: "model.mailAddress.value",
                      },
                    ],
                    staticClass: "width-260",
                    class: { error: _vm.model.mailAddress.isError },
                    attrs: { type: "text", id: "model.mailAddress.value" },
                    domProps: { value: _vm.model.mailAddress.value },
                    on: {
                      change: function ($event) {
                        return _vm.validationCheck("mailAddress")
                      },
                      input: [
                        function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.model.mailAddress,
                            "value",
                            $event.target.value
                          )
                        },
                        function ($event) {
                          return _vm.validationCheck("mailAddress", true)
                        },
                      ],
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "errors" },
                    _vm._l(_vm.model.mailAddress.errors, function (item1) {
                      return _c(
                        "div",
                        { key: _vm.model.mailAddress.errors.indexOf(item1) },
                        [
                          _c(
                            "div",
                            [
                              _c("fa-icon", {
                                attrs: { icon: "exclamation-circle" },
                              }),
                            ],
                            1
                          ),
                          _c("span", [_vm._v(_vm._s(item1.message))]),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
              _c("hr"),
              _c("div", { staticClass: "formGroup" }, [
                _c(
                  "div",
                  { staticClass: "left" },
                  [_c("fa-icon", { attrs: { icon: "lock" } })],
                  1
                ),
                _c("div", { staticClass: "right" }, [
                  _c(
                    "label",
                    {
                      staticClass: "title",
                      attrs: { for: "model.password.value1" },
                    },
                    [_vm._v("新しいパスワード")]
                  ),
                  _c("div", { staticClass: "twoColumn" }, [
                    _vm.model.password.view == false
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.password.value1,
                              expression: "model.password.value1",
                            },
                          ],
                          class: { error: _vm.model.password.isError },
                          attrs: {
                            type: "password",
                            id: "model.password.value1",
                          },
                          domProps: { value: _vm.model.password.value1 },
                          on: {
                            change: function ($event) {
                              return _vm.validationCheck("password")
                            },
                            input: [
                              function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.model.password,
                                  "value1",
                                  $event.target.value
                                )
                              },
                              function ($event) {
                                return _vm.validationCheck("password", true)
                              },
                            ],
                          },
                        })
                      : _vm._e(),
                    _vm.model.password.view == true
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.model.password.value1,
                              expression: "model.password.value1",
                            },
                          ],
                          class: { error: _vm.model.password.isError },
                          attrs: { type: "text", id: "model.password.value1" },
                          domProps: { value: _vm.model.password.value1 },
                          on: {
                            change: function ($event) {
                              return _vm.validationCheck("password")
                            },
                            input: [
                              function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.model.password,
                                  "value1",
                                  $event.target.value
                                )
                              },
                              function ($event) {
                                return _vm.validationCheck("password", true)
                              },
                            ],
                          },
                        })
                      : _vm._e(),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.model.password.view,
                          expression: "model.password.view",
                        },
                      ],
                      attrs: { type: "checkbox", id: "model.password.view" },
                      domProps: {
                        checked: Array.isArray(_vm.model.password.view)
                          ? _vm._i(_vm.model.password.view, null) > -1
                          : _vm.model.password.view,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.model.password.view,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.model.password,
                                  "view",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.model.password,
                                  "view",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.model.password, "view", $$c)
                          }
                        },
                      },
                    }),
                    _c("label", { attrs: { for: "model.password.view" } }, [
                      _vm._v("パスワードを表示"),
                    ]),
                  ]),
                  _vm.model.password.view == false
                    ? _c(
                        "label",
                        {
                          staticClass: "title",
                          attrs: { for: "model.password.value2" },
                        },
                        [_vm._v("新しいパスワード（確認）")]
                      )
                    : _vm._e(),
                  _vm.model.password.view == false
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.model.password.value2,
                            expression: "model.password.value2",
                          },
                        ],
                        class: { error: _vm.model.password.isError },
                        attrs: {
                          type: "password",
                          id: "model.password.value2",
                        },
                        domProps: { value: _vm.model.password.value2 },
                        on: {
                          change: function ($event) {
                            return _vm.validationCheck("password")
                          },
                          input: [
                            function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.model.password,
                                "value2",
                                $event.target.value
                              )
                            },
                            function ($event) {
                              return _vm.validationCheck("password", true)
                            },
                          ],
                        },
                      })
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "errors" },
                    _vm._l(_vm.model.password.errors, function (item1) {
                      return _c(
                        "div",
                        { key: _vm.model.password.errors.indexOf(item1) },
                        [
                          _c(
                            "div",
                            [
                              _c("fa-icon", {
                                attrs: { icon: "exclamation-circle" },
                              }),
                            ],
                            1
                          ),
                          _c("span", [_vm._v(_vm._s(item1.message))]),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm.model.step === "2"
        ? _c(
            "div",
            {
              staticClass: "stepMessage",
              class: { loading: _vm.model.step2ButtonLoading },
            },
            [
              _c("p", [
                _vm._v(" 「"),
                _c("strong", [_vm._v(_vm._s(_vm.model.mailAddress.value))]),
                _vm._v("」に確認コードを送信しました。"),
                _c("br"),
                _vm._v(
                  " メールに記入されている確認コードを入力してください。 "
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm.model.step === "2"
        ? _c(
            "div",
            {
              staticClass: "step2",
              class: { loading: _vm.model.step2ButtonLoading },
            },
            [
              _c("div", { staticClass: "formGroup" }, [
                _c(
                  "div",
                  { staticClass: "left" },
                  [_c("fa-icon", { attrs: { icon: "paper-plane" } })],
                  1
                ),
                _c("div", { staticClass: "right" }, [
                  _c(
                    "label",
                    {
                      staticClass: "title",
                      attrs: { for: "model.code.value" },
                    },
                    [_vm._v("確認コード")]
                  ),
                  _c("div", { staticClass: "inputCode" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.model.code.value1,
                          expression: "model.code.value1",
                        },
                      ],
                      class: { error: _vm.model.code.isError },
                      attrs: { id: "codeValue1", type: "text" },
                      domProps: { value: _vm.model.code.value1 },
                      on: {
                        change: function ($event) {
                          return _vm.validationCheck("code")
                        },
                        input: [
                          function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.model.code,
                              "value1",
                              $event.target.value
                            )
                          },
                          _vm.codeChange,
                        ],
                        keydown: _vm.codeOnKey,
                        keyup: _vm.codeOnKey,
                        focus: function ($event) {
                          return _vm.codeFocus(1)
                        },
                      },
                    }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.model.code.value2,
                          expression: "model.code.value2",
                        },
                      ],
                      class: { error: _vm.model.code.isError },
                      attrs: { id: "codeValue2", type: "text" },
                      domProps: { value: _vm.model.code.value2 },
                      on: {
                        change: function ($event) {
                          return _vm.validationCheck("code")
                        },
                        input: [
                          function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.model.code,
                              "value2",
                              $event.target.value
                            )
                          },
                          _vm.codeChange,
                        ],
                        keydown: _vm.codeOnKey,
                        keyup: _vm.codeOnKey,
                        focus: function ($event) {
                          return _vm.codeFocus(2)
                        },
                      },
                    }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.model.code.value3,
                          expression: "model.code.value3",
                        },
                      ],
                      class: { error: _vm.model.code.isError },
                      attrs: { id: "codeValue3", type: "text" },
                      domProps: { value: _vm.model.code.value3 },
                      on: {
                        change: function ($event) {
                          return _vm.validationCheck("code")
                        },
                        input: [
                          function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.model.code,
                              "value3",
                              $event.target.value
                            )
                          },
                          _vm.codeChange,
                        ],
                        keydown: _vm.codeOnKey,
                        keyup: _vm.codeOnKey,
                        focus: function ($event) {
                          return _vm.codeFocus(3)
                        },
                      },
                    }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.model.code.value4,
                          expression: "model.code.value4",
                        },
                      ],
                      class: { error: _vm.model.code.isError },
                      attrs: { id: "codeValue4", type: "text" },
                      domProps: { value: _vm.model.code.value4 },
                      on: {
                        change: function ($event) {
                          return _vm.validationCheck("code")
                        },
                        input: [
                          function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.model.code,
                              "value4",
                              $event.target.value
                            )
                          },
                          _vm.codeChange,
                        ],
                        keydown: _vm.codeOnKey,
                        keyup: _vm.codeOnKey,
                        focus: function ($event) {
                          return _vm.codeFocus(4)
                        },
                      },
                    }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.model.code.value5,
                          expression: "model.code.value5",
                        },
                      ],
                      class: { error: _vm.model.code.isError },
                      attrs: { id: "codeValue5", type: "text" },
                      domProps: { value: _vm.model.code.value5 },
                      on: {
                        change: function ($event) {
                          return _vm.validationCheck("code")
                        },
                        input: [
                          function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.model.code,
                              "value5",
                              $event.target.value
                            )
                          },
                          _vm.codeChange,
                        ],
                        keydown: _vm.codeOnKey,
                        keyup: _vm.codeOnKey,
                        focus: function ($event) {
                          return _vm.codeFocus(5)
                        },
                      },
                    }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.model.code.value6,
                          expression: "model.code.value6",
                        },
                      ],
                      class: { error: _vm.model.code.isError },
                      attrs: { id: "codeValue6", type: "text" },
                      domProps: { value: _vm.model.code.value6 },
                      on: {
                        change: function ($event) {
                          return _vm.validationCheck("code")
                        },
                        input: [
                          function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.model.code,
                              "value6",
                              $event.target.value
                            )
                          },
                          _vm.codeChange,
                        ],
                        keydown: _vm.codeOnKey,
                        keyup: _vm.codeOnKey,
                        focus: function ($event) {
                          return _vm.codeFocus(6)
                        },
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "backStep1" }, [
                    _c("span", { on: { click: _vm.backStep1 } }, [
                      _vm._v("確認コードを再送信"),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "errors" },
                    _vm._l(_vm.model.code.errors, function (item1) {
                      return _c(
                        "div",
                        { key: _vm.model.code.errors.indexOf(item1) },
                        [
                          _c(
                            "div",
                            [
                              _c("fa-icon", {
                                attrs: { icon: "exclamation-circle" },
                              }),
                            ],
                            1
                          ),
                          _c("span", [_vm._v(_vm._s(item1.message))]),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm.model.step === "1"
        ? _c("div", { staticClass: "step1Buttons" }, [
            _vm.model.step1Errors.length !== 0
              ? _c(
                  "div",
                  { staticClass: "errors" },
                  _vm._l(_vm.model.step1Errors, function (item1) {
                    return _c(
                      "div",
                      { key: _vm.model.step1Errors.indexOf(item1) },
                      [
                        _c(
                          "div",
                          [
                            _c("fa-icon", {
                              attrs: { icon: "exclamation-circle" },
                            }),
                          ],
                          1
                        ),
                        _c("span", [_vm._v(_vm._s(item1.message))]),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _c(
              "button",
              {
                attrs: {
                  disabled:
                    !_vm.model.step1ButtonActive ||
                    !_vm.validationCheck("all", true, true),
                },
                on: {
                  click: function ($event) {
                    return _vm.step1Process()
                  },
                },
              },
              [
                _vm.model.step1ButtonLoading
                  ? _c("div", { staticClass: "spinner bubble" })
                  : _vm._e(),
                !_vm.model.step1ButtonLoading
                  ? _c("span", [_vm._v("確認コードを送信")])
                  : _vm._e(),
                !_vm.model.step1ButtonLoading
                  ? _c("fa-icon", { attrs: { icon: "arrow-right" } })
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.model.step === "2"
        ? _c("div", { staticClass: "step2Buttons" }, [
            _vm.model.step2Errors.length !== 0
              ? _c(
                  "div",
                  { staticClass: "errors" },
                  _vm._l(_vm.model.step2Errors, function (item1) {
                    return _c(
                      "div",
                      { key: _vm.model.step2Errors.indexOf(item1) },
                      [
                        _c(
                          "div",
                          [
                            _c("fa-icon", {
                              attrs: { icon: "exclamation-circle" },
                            }),
                          ],
                          1
                        ),
                        _c("span", [_vm._v(_vm._s(item1.message))]),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _c(
              "button",
              {
                attrs: {
                  disabled:
                    !_vm.model.step2ButtonActive ||
                    !_vm.validationCheck("all", true, true),
                },
                on: {
                  click: function ($event) {
                    return _vm.step2Process()
                  },
                },
              },
              [
                _vm.model.step2ButtonLoading
                  ? _c("div", { staticClass: "spinner bubble" })
                  : _vm._e(),
                !_vm.model.step2ButtonLoading
                  ? _c("span", [_vm._v("アカウント登録実行")])
                  : _vm._e(),
                !_vm.model.step2ButtonLoading
                  ? _c("fa-icon", { attrs: { icon: "arrow-right" } })
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.model.step === "3"
        ? _c("div", { staticClass: "step3Buttons" }, [
            _c("div", { staticClass: "completed" }, [
              _c(
                "div",
                { staticClass: "icon" },
                [_c("fa-icon", { attrs: { icon: "check" } })],
                1
              ),
              _c("span", [_vm._v("アカウントの登録が完了しました")]),
            ]),
            _c(
              "button",
              {
                on: {
                  click: function ($event) {
                    return _vm.step3Process()
                  },
                },
              },
              [
                _c("span", [_vm._v("ログイン")]),
                _c("fa-icon", { attrs: { icon: "sign-in-alt" } }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]),
    _c("div", { staticClass: "code" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.model.step,
            expression: "model.step",
          },
        ],
        attrs: { type: "text" },
        domProps: { value: _vm.model.step },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.model, "step", $event.target.value)
          },
        },
      }),
      _c("pre", [_vm._v(_vm._s(_vm.model))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }