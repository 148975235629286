export class Model {
  public step: string = "1";
  public step1ButtonActive: boolean = true;
  public step1ButtonLoading: boolean = false;
  public step1Errors: Array<Error> = new Array<Error>();
  public step2ButtonActive: boolean = true;
  public step2ButtonLoading: boolean = false;
  public step2Errors: Array<Error> = new Array<Error>();
  public mailAddress: InputText = new InputText(100);
  public password: InputPassword = new InputPassword(50, 8);
  public code: InputCode = new InputCode();
}
export class InputText {
  public constructor(maxLength: number, minLength = 0) {
    this.maxLength = maxLength;
    this.minLength = minLength;
  }
  public value: string = "";
  public maxLength: number = 0;
  public minLength: number = 0;
  public errors: Array<Error> = new Array<Error>();
  get isError(): boolean {
    return this.errors.length > 0;
  }
}
export class InputPassword {
  public constructor(maxLength: number, minLength = 0) {
    this.maxLength = maxLength;
    this.minLength = minLength;
  }
  public value1: string = "";
  public value2: string = "";
  public view: string = "";
  public maxLength: number = 0;
  public minLength: number = 0;
  public errors: Array<Error> = new Array<Error>();
  get isError(): boolean {
    return this.errors.length > 0;
  }
}
export class InputCode {
  public clear() {
    this.value1 = "";
    this.value2 = "";
    this.value3 = "";
    this.value4 = "";
    this.value5 = "";
    this.value6 = "";
  }
  get value(): string {
    return (
      this.value1 +
      this.value2 +
      this.value3 +
      this.value4 +
      this.value5 +
      this.value6
    );
  }
  public value1: string = "";
  public value2: string = "";
  public value3: string = "";
  public value4: string = "";
  public value5: string = "";
  public value6: string = "";
  public focusNumber: number = 0;
  public errors: Array<Error> = new Array<Error>();
  get isError(): boolean {
    return this.errors.length > 0;
  }
}
export class Error {
  public constructor(message: string, index: number | null = null) {
    this.message = message;
    this.index = index;
  }
  public message: string;
  public index: number | null;
}
