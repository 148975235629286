export class Model {
  public mailAddress: string = "";
  public password: string = "";
  public errors: Array<Error> = new Array<Error>();
  public isError(index: number | null = null): boolean {
    if (index != null) {
      let result = false;
      for (let i = 0; i < this.errors.length; i++) {
        if (this.errors[i].index == index) {
          result = true;
        }
      }
      return result;
    } else {
      return this.errors.length > 0;
    }
  }
}
export class Error {
  public constructor(message: string, index: number | null = null) {
    this.message = message;
    this.index = index;
  }
  public message: string;
  public index: number | null;
}
