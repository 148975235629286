// eslint-disable-next-line
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import BaseVue from "@/plugin/BaseVue";
import * as model from "./Model";
import SignUp from "@/components/SignUp/SignUp.vue";
import ForgotPassword from "@/components/ForgotPassword/ForgotPassword.vue";
import { commonModule } from "@/store/modules/Common";

@Component({
  components: {
    SignUp,
    ForgotPassword,
  },
})
export default class extends BaseVue {
  public model: model.Model = new model.Model();
  public created() {
    const routeInstance = this.$route;
    this.setMeta(routeInstance);
    commonModule.load();
    this.model.mailAddress = commonModule.mailAddress;
  }
  public async mounted() {
    await this.authCheck();
  }
  public signIn(): void {
    this.model.errors = new Array<model.Error>();
    this.creatorUserManager.signIn(
      {
        mailAddress: this.model.mailAddress,
        password: this.model.password,
      },
      {
        success: () => {
          this.authCheck();
          this.model.password = "";
          localStorage.setItem("mailaddress", this.model.mailAddress);
        },
        failure: (err: Error) => {
          this.model.password = "";
          switch (err.name) {
            case "InvalidParameterException":
              this.model.errors.push(
                new model.Error(
                  "メールアドレスまたはパスワードを入力してください"
                )
              );
              break;
            case "NotAuthorizedException":
              this.model.errors.push(
                new model.Error("メールアドレスまたはパスワードが違います")
              );
              break;
            default:
              this.model.errors.push(
                new model.Error("不明なエラーが発生しました")
              );
          }
        },
      }
    );
  }
  public signUpModalOpen(): void {
    this.$modal.show("signUp");
  }
  public signUpModalClose(): void {
    this.$modal.hide("signUp");
  }
  public forgotPasswordModalOpen(): void {
    this.$modal.show("forgotPassword");
  }
  public forgotPasswordModalClose(): void {
    this.$modal.hide("forgotPassword");
  }
  public signUpModalCompleted(event: any): void {
    this.$modal.hide("signUp");
    this.model.mailAddress = event.mailAddress;
    this.model.password = event.password;
    this.signIn();
  }
  public forgotPasswordCompleted(event: any): void {
    this.$modal.hide("forgotPassword");
    this.model.mailAddress = event.mailAddress;
    this.model.password = event.password;
    this.signIn();
  }
}
